import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-deno-and-will-it-replace-nodejs"
    }}>{`What is Deno and will it replace Node.js?`}</h1>
    <h5 {...{
      "id": "june-24-2020--wrocław-typescript"
    }}>{`June 24, 2020 | `}<a parentName="h5" {...{
        "href": "https://github.com/WrocTypeScript/"
      }}>{`Wrocław TypeScript`}</a></h5>
    <h5 {...{
      "id": "june-26-2020--aptitude-softwares-office"
    }}>{`June 26, 2020 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/what-is-deno"
        }}>{`https://slides.com/kajetansw/what-is-deno`}</a></li>
      <li parentName="ul">{`🎥 `}<strong parentName="li">{`Recording`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=nheSLk6pwR0"
        }}>{`https://www.youtube.com/watch?v=nheSLk6pwR0`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~30 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: talk + live coding`}</li>
    </ul>
    <hr></hr>
    <p>{`Deno is here! May 2020 marked the official premiere of Deno 1.0 and created major uproar among Node.js users and the JavaScript community.`}</p>
    <p>{`What is it? How it works? What features does it have? How to use it? And more importantly: Will it replace Node.js on its throne?`}</p>
    <p>{`This presentation serves as a brief overview of the technology with a short live coding session included.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      